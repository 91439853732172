<template>
    <div class="mywallet-wrapper">
        <section class="mywallet-nth1 mt-0">
            <article class="assets-item bom-none">
                <ul class="wasset-list wasset-listnth3" v-if="userInfo.info">
                    <li>
                        <p class="w-numb">
                            <span v-if="inviteCount">{{inviteCount}}</span>
                            <span v-else>0</span>
                        </p>
                        <span class="w-unit">人</span>
                        <h5 class="w-name">已邀请用户</h5>
                    </li>
                    <li>
                        <p class="w-numb yellow">
                            ￥
                            <span>
                                {{userInfo.info.commission | changePrice}}
                                <i>.{{userInfo.info.commission | splicePrice}}</i>
                            </span>
                        </p>
                        <span class="w-unit">元</span>
                        <h5 class="w-name">已赚取佣金</h5>
                    </li>
                    <li>
                        <p class="w-numb">
                            ￥
                            <span>
                                {{commission_freeze | changePrice}}
                                <i>.{{commission_freeze | splicePrice}}</i>
                            </span>
                        </p>
                        <span class="w-unit">元</span>
                        <h5 class="w-name">待结算佣金</h5>
                    </li>
                </ul>
            </article>
        </section>

        <div class="sharelink-wrap">
            <div class="sharelink-tips">
                <h4>分享给身边的同事或好友注册，可以赚取更多佣金哦！</h4>
                <p>复制链接发送给同事和好友</p>
            </div>
            <div class="copy-wrap">
                <div class="copy-cont">
                    <span class="copy-txt" style="500px !important;" v-text="invitationUrl"></span>
                    <div class="tag-read" :data-clipboard-text="invitationUrl" @click="copy">复制去分享</div>
                </div>
            </div>
        </div>

        <div class="mywallet-nth2">
            <div class="wallettabs-wrap">
                <el-tabs v-model="activeName" @tab-click="tabClick">
                    <el-tab-pane label="已邀请用户列表" name="1"></el-tab-pane>

                    <el-tab-pane label="累计佣金明细" name="2"></el-tab-pane>

                    <el-tab-pane label="待结算佣金明细" name="3"></el-tab-pane>
                </el-tabs>
            </div>

            <div class="ticket-box" v-loading="loading">
                <!--已邀请用户列表-->
                <div class="aticket-wrap" v-if="activeName == '1'">
                    <el-table :data="inviteList" key="1">
                        <!-- <el-table-column prop="index"
                                         label="序号"
                                         width="100">
                        </el-table-column>-->

                        <el-table-column prop="gid" label="用户ID" width="100"></el-table-column>

                        <el-table-column prop="name" label="用户实名"></el-table-column>

                        <el-table-column prop="mobile" label="用户注册手机号" width="130"></el-table-column>

                        <el-table-column prop="user_type_name" width="120" label="用户身份"></el-table-column>

                        <el-table-column prop="ctime" width="170" label="邀请成功注册时间"></el-table-column>
                    </el-table>

                    <!--分页-->
                    <pagination
                        v-if="inviteList && inviteList.length>0"
                        :pageSize="pageSize"
                        :currentPage="currentPage"
                        :pageTotal="pageTotal"
                        @current-change="inviteNewPage"
                    ></pagination>
                </div>

                <!--累计佣金明细-->
                <div class="aticket-wrap" v-if="activeName == '2'">
                    <el-table :data="commissionList" key="2">
                        <el-table-column prop="sn" label="项目编号" width="100"></el-table-column>

                        <el-table-column prop="task_name" label="项目名称"></el-table-column>

                        <el-table-column prop="username" label="用户实名" width="160"></el-table-column>

                        <el-table-column prop="gid" label="用户ID" width="80"></el-table-column>

                        <el-table-column prop="mobile" label="用户注册手机号" width="120"></el-table-column>

                        <el-table-column prop="user_type" label="用户身份" width="80"></el-table-column>

                        <el-table-column prop="price" width="100" label="已收佣金"></el-table-column>

                        <el-table-column prop="create_time" width="100" label="收取时间"></el-table-column>
                    </el-table>

                    <!--分页-->
                    <pagination
                        v-if="commissionList && commissionList.length>0"
                        :pageSize="pageSize"
                        :currentPage="currentPage"
                        :pageTotal="pageTotal"
                        @current-change="commissionNewPage"
                    ></pagination>
                </div>

                <!--待结算佣金明细-->
                <div class="aticket-wrap"
                     v-if="activeName == '3'">
                    <el-table :data="commissionNopayList"
                              key='3'>
                        <el-table-column prop="sn"
                                         label="项目编号"
                                         width="100">
                        </el-table-column>

                        <el-table-column prop="task_name"
                                         label="项目名称">
                        </el-table-column>

                        <el-table-column prop="username"
                                         label="用户实名"
                                         width="150">
                        </el-table-column>

                        <el-table-column prop="gid"
                                         label="用户ID"
                                         width="80">
                        </el-table-column>

                        <el-table-column prop="mobile"
                                         width="120"
                                         label="用户注册手机号">
                        </el-table-column>

                        <el-table-column prop="user_type"
                                         width="80"
                                         label="用户身份">
                        </el-table-column>

                        <el-table-column prop="price"
                                         width="100"
                                         label="待结算佣金">
                        </el-table-column>

                        <el-table-column prop="time"
                                         width="110"
                                         label="酬金托管时间">
                        </el-table-column>

                        <el-table-column prop="task_desc"
                                         width="200"
                                         label="说明">
                        </el-table-column>

                    </el-table>
                    <!--分页-->
                    <pagination
                        v-if="commissionNopayList && commissionNopayList.length>0"
                        :pageSize="pageSize"
                        :currentPage="currentPage"
                        :pageTotal="pageTotal"
                        @current-change="commissionNopayNewPage"
                    ></pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Pagination from "@/components/common/Pagination.vue";
import { mapActions, mapState } from "vuex";
export default {
    name: "mycommission",
    metaInfo() {
        return {
            title: "我的佣金 - 询龙网",
            meta: [
                {
                    name: "keywords",
                    content: ""
                }, {
                    name: "description",
                    content: ''
                }
            ]
        }
    },
    components: {
        Pagination //分页
    },

    computed: {
        ...mapState(["userInfo"])
    },

    data() {
        return {
            activeName: "1", //tabs激活项
            currentPage: 1, //分页 - 当前页
            pageTotal: 0, //分页 - 总页数
            pageSize: 10, //分页 - 每页显示条数
            invitationUrl: "", //邀请链接
            inviteList: [], //邀请用户列表
            inviteCount: 0, //已邀请人
            commissionList: [], //佣金日志列表
            commissionNopayList: [], //待结算佣金日志
            commission_freeze: 0, //待结束佣金
            loading: false
        };
    },

    methods: {
        ...mapActions("userInfo", ["ajaxUserInfo"]), //刷新用户数据方法

        //切换tabs事件
        tabClick(val) {
            this.currentPage = 1;
            switch (parseInt(val.index) + 1) {
                case 1:
                    this.loadInviteList(); //加载邀请用户列表
                    break;

                case 2:
                    this.loadCommissionList(); //加载佣金累计明细
                    break;

                case 3:
                    this.loadCommissionNopayList(); //加载待结算佣金累计明细
                    break;

                default:
                    break;
            }
        },

        //获取我邀请用户列表
        loadInviteList() {
            let _this = this;
            _this.loading = true;
            this.post(
                "/pay/pay/inviter_list",
                {
                    page: _this.currentPage,
                    limit: _this.pageSize
                },
                function (res) {
                    _this.loading = false;
                    if (res.code == 200) {
                        _this.inviteList = res.data.list;
                        _this.pageTotal = res.data.count;
                        _this.inviteCount = res.data.count;
                        _this.ajaxUserInfo(); //刷新我的钱包信息
                    }
                }
            );
        },

        //邀请用户列表分页
        inviteNewPage(page) {
            this.currentPage = page;
            this.loadInviteList();
        },

        //获得佣金明细
        loadCommissionList() {
            let _this = this;
            _this.loading = true;
            _this.post(
                "/log/log/commission_list",
                {
                    cate: 13, //佣金列表
                    order_type: 1, //1:资金；2:点券；3:报名次数
                    page: _this.currentPage,
                    limit: _this.pageSize
                },
                function (res) {
                    _this.loading = false;
                    if (res.code == 200) {
                        _this.ajaxUserInfo(); //刷新我的钱包信息
                        _this.commissionList = res.data.list;
                        _this.pageTotal = res.data.count;
                    }
                }
            );
        },

        //佣金分页
        commissionNewPage(page) {
            this.currentPage = page;
            this.loadCommissionList();
        },

        //获得佣金明细
        loadCommissionNopayList() {
            let _this = this;
            _this.loading = true;
            _this.post(
                "/log/log/commission_nopay_list",
                {
                    cate: 13, //佣金列表
                    order_type: 1, //1:资金；2:点券；3:报名次数
                    page: _this.currentPage,
                    limit: _this.pageSize
                },
                function (res) {
                    _this.loading = false;
                    if (res.code == 200) {
                        _this.ajaxUserInfo(); //刷新我的钱包信息
                        _this.commissionNopayList = res.data.list;
                        _this.pageTotal = res.data.count;
                        _this.commission_freeze = res.data.commission_freeze;
                    }
                }
            );
        },

        //佣金分页
        commissionNopayNewPage(page) {
            this.currentPage = page;
            this.loadCommissionNopayList();
        },

        //复制功能
        copy() {
            var clipboard = new this.Clipboard(".tag-read");
            clipboard.on("success", e => {
                this.$message({
                    message: "复制成功",
                    type: "success"
                });
                clipboard.destroy();
            });
            clipboard.on("error", e => {
                clipboard.destroy();
            });
        },

        //到个人中心
        geSelfCenter(uk) {
            //存储当前值
            sessionStorage.setItem(
                "currentPageUrl",
                document.location.pathname + document.location.search
            );
            this.$router.push("/firm/index?view=1&uk=" + uk);
        }
    },

    created() {
        this.loadInviteList(); //获取已邀请用户列表
        this.loadCommissionNopayList(); //加载待结束佣金

        //邀请码显示信息
        setTimeout(() => {
            if (this.userInfo.info && this.userInfo.info.attestation == 2) {
                this.invitationUrl =
                    window.location.origin +
                    "/login/index?type=register&code=" +
                    this.userInfo.info.invitation_code;
            } else {
                this.invitationUrl = "认证用户才会生成邀请码哦";
            }
        }, 500);

        if (this.$route.query.tab) {
            this.activeName = this.$route.query.tab;
            this.currentPage = 1;
            this.loadCommissionList(); //加载佣金累计明细
        }
    }
};
</script>

<style lang="less">
@import "../../../styles/index.less";
.emptyData {
    width: 100%;
    text-align: center;
    margin-top: 10%;
    margin-bottom: 20%;
    color: #999;
}
</style>




